$(document).ready(function(){

	setTimeout(function(){
		$("body").removeClass("portal-popup"); 
	}, 3000);

	/***Start MDWA-5124 As a portal user, I want to adjust screen font size on pages (little A and big A icon)*****/
	$("a.fontSize","#font-adjust").on('click',function(e){
		e.preventDefault();
		console.log('fontAdjust clicked!');
		var size = $(this).attr('size');
		size = Number(size);
		$('html').css('zoom',size);
	});
	/***End MDWA-5124 ****/	
	
	
	/***MDWA-5318 - Change font to Times New Roman, Default will be Ubuntu, Admin can change to Times New Roman in Theme settings for all Sites */
	var _fontType = $('body').attr('font');
	if (_fontType && _fontType == 'Times New Roman') {
	    $('body').css('font-family', 'Times New Roman, Times, serif');
    }
	/***End MDWA-5318 ****/		 
});
